<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="() => {open = false; onClose()}">
            <div class="flex items-center justify-center text-center">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="top-0 sm:top-8 inline-block sm:rounded-md h-fit align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full">
                        <div class="w-full bg-gray-900 text-white flex justify-between h-11 items-center px-2.5">
                            <p class="text-white font-bold pl-8">{{title}}</p>
                            <Icon name="XIcon" size="6" isOutline color="white" @click="() => {open = false; onClose()}" class="cursor-pointer"/>
                        </div>
                        <div class="w-full p-10">
                             <div v-for="form in contactFields" :key="form.label" class="w-full">
                                <Input v-if="form.type === 'string' || form.type === 'email'" :type="form.type" v-model:value="formObject[form.name]" :label="form.label" class="w-full lg:w-4/5"/>
                                <Select v-else-if="form.type === 'select'" :items="form.options" :type="form.type" v-model:selectedItem="formObject[form.name]" :label="form.label" class="w-full lg:w-4/5 mt-4"/>
                                <Textarea v-else-if="form.type === 'textarea'" :type="form.type" v-model:value="formObject[form.name]" :label="form.label" class="w-full mt-4"/>
                            </div>
                        </div>
                        <div class="flex justify-center w-full mt-1 mb-8">
                            <Button variant="primary" content="Send"
                                class="px-16 py-3 font-semibold text-md shadow-lg bg-primary text-white" :onClick="() => $emit('onClick')" />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import Icon from '../../../atoms/Icons/Icons.vue'
    import Button from '../../../atoms/Button/Button.vue'
    import Text from '../../../atoms/Text/Text.vue'
    import Input from '../../../molecules/Inputs/Component/Component.vue'
    import Select from '../../../molecules/Selects/Component/Component.vue'
    import Textarea from '../../../molecules/Textareas/Simple/Simple.vue'
    import {ref} from "vue";

    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            Icon,
            Button,
            Text,
            Input,
            Select,
            Textarea
        },
        watch: {
            isOpen: function (val) {
                this.open = ref(val)
            }
        },
        props: {
            title: {
                type: String,
                default: "Contact"
            },
            isOpen: {
                type: Boolean,
                default: false,
            },
            onClose: {
                type: Function,
                default: () => {}
            },
            contactFields: {
                type: Array,
                default: () => []
            },
            onClick: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            const open = ref(this.isOpen)
            const formObject = ref({});
            return {
                open,
                formObject
            }
        },
        methods: {
            getFormObject() {
                return this.formObject;
            },
            setFormObject(value) {
                this.formObject = value
            }
        }
    };
</script>