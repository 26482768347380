<template>
  <div class="pt-8 pb-5 mt-5 policy-box">
    <div class="relative">
      <div class="pos-top-border left"/>
      <h4 class="font-bold text-lg mb-2.5">{{ title }}</h4>
      <p class="text-sm font-bold opacity-70 mb-5 leading-6 tracking-wide" v-if="notice" v-html="notice"/>
      <template v-for="content in subContents">
        <p v-if="content.subTitle" class="mb-2.5"><i>{{ content.subTitle }}</i></p>
        <p class="text-sm opacity-70 mb-5 leading-6 tracking-wide" v-if="content.subContent"
           v-html="content.subContent"/>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    notice: {
      type: String,
      default: ""
    },
    subContents: {
      type: Array,
      default: () => [],
    },
  }
};
</script>

<style>
a {
  color: #29baac;
  text-decoration: underline;
}

ul {
  list-style-type: disc;
  padding-left: 40px;
}

a:hover {
  text-decoration: none;
}

.pos-top-border.left {
  left: 0;
  right: auto;
}

.pos-top-border {
  position: absolute;
  top: -20px;
  width: 50px;
  height: 3px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #29baac;
}

.policy-box {
  border-bottom: 1px solid transparent;
}
</style>
