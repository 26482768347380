<template>
  <Template
    v-bind="{
  	  ...params,
      subscriptionCards: params.subscriptionCards,
      registerUrl: navigationUrl(),
      saveContactRequest: (data) => saveContactRequest(data),
    }"
    ref="pricingRef"
    @goToPayment="goToPayment"
  />
</template>

<script>
import Template from '../components/templates/Pricing/Pricing'
import {c} from '../components/constants'

export default {
  components: {
  	Template
  },
  data () {
    return {
      params: c.pricing
    }
  },
  methods: {
    navigationUrl() {
        return this.state?.currentUser?.id ? '/#/application' : '/#/register'
    },
    goToPayment() {
      this.$router.push({name: this.state?.currentUser?.id ? 'Add Payment' : 'Register'})
    },
    async saveContactRequest(data) {
        if (!data?.email?.trim()) {
            this.actions.alert.showError({message: 'Please insert email address!'});
            return false;
        }
        if (!data?.message?.trim()) {
            this.actions.alert.showError({message: 'Please insert message!'});
            return false
        }
        const params = {
            name: 'contact',
            subject: data.message,
            emails: {set: [data?.email]},
            comment: data.message,
        }
        const {saveContactRequest} = await this.actions.user.saveContactRequest({data: params});
        if(saveContactRequest && saveContactRequest.id) {
            this.actions.alert.showSuccess({message: 'Your message send successfully'});
            this.$refs.pricingRef.onCloseModal()
        }
    }
  },
}
</script>
