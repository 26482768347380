<template>
  <h1 class="text-sm text-gray-500 font-semibold text-center mb-4 main-title">{{ mainTitle }}</h1>
  <div class="bg-primary border border-green-600 overflow-hidden rounded-md">
    <div class="py-5 font-bold text-white text-center header-wrapper flex justify-center items-center">
      <div>
        <h5 class="text-base" v-if="title === 'CONTACT FOR PRICING'">CONTACT FOR <br/> PRICING</h5>
        <h5 class="text-base" v-else>{{ title }}</h5>
        <h1 class="text-4xl" v-if="price">${{ price }}</h1>
      </div>
    </div>
    <div class="bg-white py-5">
      <div v-for="item in items">
        <p class="text-gray-500 text-sm font-semibold xl:pl-8 xl:pr-8 md:px-8 pl-2.5 pr-2.5">{{ item.title }}</p>
        <ul class="mt-2 mb-2.5 list-none md:px-8 xl:pl-8 xl:pr-8 pl-2.5 pr-2.5 opacity-70 subscription-ul pb-5">
          <li v-for="data in item.data"><p class="py-1.5 text-sm">{{ data }}</p></li>
        </ul>
      </div>
    </div>
    <div class="bg-light-gray py-5 text-center">
      <button class="bg-primary py-1.5 px-4 rounded-full font-bold text-sm no-underline text-white"
              v-if="btnText === 'Contact'" @click="openContactModal">{{ btnText }}
      </button>
      <a @click="$emit('onSubscribe')"
         class="bg-primary py-1.5 px-4 rounded-full font-bold text-sm no-underline text-white" v-else-if="btnText !== 'Contact' && !isPaid">{{ btnText }}</a>
      <Text v-if="btnText !== 'Contact' && isPaid" size="xs" color="primary" :content="`Your subscription expires on ${moment(isPaid?.nextRenewalDate).format('MM/DD/YY')}`" align="center"/>

    </div>
  </div>
</template>

<script>
import Text from '../../atoms/Text/Text'
import moment from 'moment'
export default {
  components: {
    Text,
  },
  data() {
    return {
      moment
    }
  },
  props: {
    mainTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => []
    },
    registerUrl: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: ""
    },
    openContactModal: {
      type: Function,
      default: () => {
      }
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style>
.main-title {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-wrapper {
  min-height: 104px;
}

.bg-light-gray {
  background: #f0f0f0;
}

.text-dark-gray {
  color: #989898;
}

.subscription-ul > li {
  border-bottom: 1px solid #e6e6e6;
  line-height: 24px;
  position: relative;
  padding-left: 20px;
}

.subscription-ul > li:before {
  content: '✓';
  position: absolute;
  left: 0;
  top: 5px;
}
</style>
