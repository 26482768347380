<template>
  <div class="lg:mt-10 mt-28">
    <h1 class="text-4xl font-bold text-center mb-4 text-gray-700 sm:text-5xl">Pricing</h1>
    <div class="text-sm font-semibold text-center">YOUR #1 SOURCE FOR NEED-BASED FINANCIAL AID!</div>
    <div class="pt-5 pb-16">
      <div class="my-10 px-3.5 mx-auto w-7xl max-w-7xl block md:flex justify-center">
        <div class="w-full lg:w-5/12 px-3.5" :class="i === 1 ? 'mt-15 md:mt-0' : ''" v-for="(subscriptionCard, i) in subscriptionCards">
          <div class="md:px-8 xl:px-16">
            <SubscriptionCard v-bind="subscriptionCard" :isPaid="isPaid"
                              :openContactModal="() => { isContactModal = true }" @onSubscribe="() => $emit('goToPayment')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ContactModal :isOpen="isContactModal" :onClose="() => onCloseModal()"
                :contactFields="contactFields" @onClick="formAction" ref="formRef"/>
</template>

<script>
import SubscriptionCard from "../../organisms/SubscriptionCard/SubscriptionCard.vue";
import Item from "../../organisms/TermsAndPolicy/ContentItem/Item.vue";
import ContactModal from "../../organisms/Modals/ContactModal/ContactModal.vue";
import {c} from "../../constants";
import {ref} from "vue";
import {json} from "overmind";

export default {
  components: {
    SubscriptionCard,
    Item,
    ContactModal
  },
  data() {
    console.log(this.state.currentUser)
    return {
      isContactModal: false,
      contactFields: c.home.subFooter.forms,
      isPaid: json(this.state.currentUser)?.productSubscriptions?.reverse()?.find(p => p?.product?.name === 'Individual')
    }
  },
  setup(props) {
    const formRef = ref();
    const formAction = () => {
      props.saveContactRequest(formRef.value.getFormObject())
    };
    const setFormObject = (value) => {
      formRef.value.setFormObject(value)
    };
    return {
      formRef,
      formAction,
      setFormObject
    };
  },
  props: {
    subscriptionCards: {
      type: Array,
      default: () => []
    },
    registerUrl: {
      type: String,
      default: ""
    },
    descTitle: {
      type: String,
      default: "",
    },
    descItems: {
      type: Array,
      default: () => [],
    },
    saveContactRequest: {
      type: Function,
      default: () => {
      }
    }
  },
  methods: {
    onCloseModal() {
      this.isContactModal = false
      this.setFormObject({email: '', role: null, message: ''})
    }
  }
};
</script>

<style>
.text-dark-black {
  color: #3a4050;
}
</style>
