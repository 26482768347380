
<template>
  <div>
    <label
      for="comment"
      class="block text-sm font-medium text-gray-700"
    >{{
      label
    }}</label>
    <div class="mt-1">
      <textarea
        id="comment"
        v-model="inputValue"
        rows="6"
        :class="customClass"
        name="comment"
        :placeholder="placeholder"
				@focus="$emit('onFocus')"
        class="
          shadow
          border
          border-gray-300
          focus:ring-primary focus:border-primary
          block
          w-full
          sm:text-sm
          rounded-md
          outline-none
          px-2 py-2
        "
      />
    </div>
  </div>
</template>
<script>
/**
 * - Use it to show simple textarea
 */
export default {
  props: {
    /**
     * Use to show what textarea is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of textarea
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to textarea
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of textarea
     */
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
